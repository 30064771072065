<script>
import axios from 'axios'
import { ref } from "vue";
import { useCookies } from "vue3-cookies";
import serverConfig from '@/helpers/config';
//import Swal from "sweetalert2";
export default {
  name: "dropzone",
  props: [
    'projectID',
    'data',
    'bucket',
    'type',
    'fileType',
    'fileAccept',
  ],
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    const { cookies } = useCookies();   
    let tk = 'Bearer '+cookies.get('tk')

    return { active, toggleActive, tk };
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      fileStorage:[],
      uploadValue:0,
      fileList:null,
      fileData:[],
      file:'',
     
    }
  },
  mounted(){
   
  },
  methods:{
    previewImage(event) {
      this.uploadValue=0;
      this.fileList=null;
      this.fileData = event.target.files;
      console.log(this.fileData)
      this.onUpload()
    },
    handleUpload(){
          this.file = this.$refs.file.files[0]
      },
      uploadFile(){
        const formData = new FormData()
        formData.append("file", this.file)
        axios.post(this.UrlServer+'upload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
        }
      ,
    async onUpload(){
      const formData = new FormData()
      /**
       * projectID:  Schema.Types.ObjectId,
        customerID:  Schema.Types.ObjectId,
        customerName:{type:String},
        authorTags:{type:String},
        area:{type:String},
        filetype:{type:String},
       */

      formData.append('bucket', this.bucket)
      formData.append('projectID', this.projectID)
      if (this.data.customerDetails) {
        if (this.data.customerDetails._id) {
          formData.append('customerID', this.data.customerDetails._id)
        }
        if (this.data.customerDetails.ragSoc) {
          let name = `${this.data.customerDetails.ragSoc} `
          if (this.data.customerDetails.nome) {
            name += ` ${this.data.customerDetails.nome} `
          }
          if (this.data.customerDetails.cognome) {
            name += ` ${this.data.customerDetails.cognome} `
          }
          formData.append('customerName', name)
        }
      }
      
      if (this.datastore) {
        if (this.datastore.auth.currentUser) {
          let user =this.datastore.auth.currentUser.firstName +' '+this.datastore.auth.currentUser.lastName
          if (this.datastore.auth.currentUser.email) {
            user += ` ${this.datastore.auth.currentUser.email} `
          }
          formData.append('authorTags', user)
          let author = {}
          author.uid = this.datastore.auth.currentUser.uid
          author.name = this.datastore.auth.currentUser.firstName +' '+this.datastore.auth.currentUser.lastName
          author.email = this.datastore.auth.currentUser.email

          formData.append('author', author)
        }
      }

      for (const i of Object.keys(this.fileData)) {
        formData.append('files', this.fileData[i])
      }

      //axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      axios.post(this.UrlServer+'storage/multi-upload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        this.$emit('getParent')
      })
      .catch((err) => {
        console.log(err);
      });
    },
    extractFileDesc(obj){

      for (const i of Object.keys(obj)) {
       
        return obj[i]
      }

    },
    async getList(){
      axios.get(this.UrlServer+'read-files', {headers: { authorization:this.tk}})
      .then((response) => {
        if (response.data)  {
          this.fileStorage = response.data
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    async getBucketList(){
      axios.get(this.UrlServer+'storage/bucket/by-code',{ params: { bucket: this.bucket }, headers: { 'authorization':this.tk} })
      .then((response) => {
        if (response.data)  {
          this.fileStorage = response.data
        }
      })
      .catch((err) => {
        console.log(err);
      });
      
    }
  }
};
</script>
<template>
  <div>
    <input class="form-control" id="multiFileList" type="file" :accept="fileAccept"  multiple="multiple" @change="previewImage" />
  </div>
</template>



